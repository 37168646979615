import React, { useState, useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import 'react-image-lightbox/style.css';
import './App.scss';
import Images from './Images';

window.onscroll = function() {scrollFunction()};

function scrollFunction() {

  // const isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/));

  const scroll = document.body.scrollTop || document.documentElement.scrollTop;
  const val = (scroll / 5) > 100 ? 100 : scroll / 5; //make sure it's not bigger than a 100 on reload
  const vh = 100 - val;
  const video = document.querySelector('.App-header video');
  if (vh < 15) video.pause();
  else video.play();
  document.getElementsByClassName('App-header')[0].style.height = vh + 'vh';
  document.getElementsByClassName('wrap')[0].style.paddingTop = (100 - (val * 0.28)) + 'vh';
  const v_opacity = ((800 - val * 8) / 1000);
  const s_opacity = (10 - val) / 10;

  document.querySelector('.App-header .bg').style.opacity = v_opacity;
  document.querySelector('#scroll-indication').style.opacity = s_opacity;
}

function App() {

  const [shows, setShows] = useState([]);

  useEffect(() => {
    // Fetch data from API
    fetch('https://us-central1-lv-gigs.cloudfunctions.net/allShows?groupTours=true&groupUpcoming=true')
      .then(response => response.json())
      .then(data => setShows(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img className="logo" src="img/logo_sm.png" width="1150" height="330" alt="Loch Vostok" />
        </div>
        <div id="scroll-indication">
          <div className="round">
            <div id="arrows">
              <span className="arrow first down"></span>
              <span className="arrow second down"></span>
            </div>
          </div>
        </div>
        <div className="bg">
          <video width="100%" height="100%" loop autoPlay playsInline muted>
            <source src="video_header_sm.mp4" />
          </video>
        </div>
      </header>
      <Grid fluid className="wrap">
        <Row>
          <Col xs={12}>
            <iframe className="hero" width="100%" height="582" src="https://www.youtube.com/embed/zOos5-wWtxY?si=_8HYXwhLfruEbPGF" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Col>
        </Row>
      	<Row>
        	<Col xs={12} sm={12}>
            <div className="info">
              <p>
                Born in the year 2000 out of necessity to create music that did not exist at the time. Refusing to stagnate. Unwilling to die. Combining raw beauty with complexity and finesse. Sometimes uncomfortable, but never boring. Harsh and soaring multi layered vocals. Pounding, complex and punishing rhythms. Shredalicious solos. Fat choruses and poisonous hooks.
              </p>
              <p>
                <b>New album: Opus Ferox II - Mark of the Beast out now!</b><br />
                The second part of the Opus Ferox saga brings you the same level of intricate modern progressive metal, a dynamic musical orgy in chaos and order. Cheese and chug combo platter.
              </p>
              <p>
                Among the members of Loch Vostok you will find musicians from a great number of active (and countless inactive) bands. Ranging from <i>F.K.Ü</i>, <i>One Hour Hell</i> and <i>Farsoth</i>, to <i>The Murder of My Sweet</i>, <i>Sodomisery</i> and <i>Anima Morte</i> (and many more).
              </p>
              <p><br />Contact: <a href="mailto:info@lochvostok.com">info@lochvostok.com</a></p>
            </div>
          </Col>
        </Row>
        <Row className="band">
          <Col xs={12} sm={12}>
            <img id="lv2023" src="img/loch_vostok_2023_3.jpg" alt="Loch Vostok 2023"/>
          </Col>
          <Col xs={4} sm={4} md={2}>
            <p>WILLIAM PARKSTAM<br />Drums</p>
          </Col>
          <Col xs={4} sm={4} md={2}>
            <p>JONAS RADEHORN<br />Lead Vocals</p>
          </Col>
          <Col xs={4} sm={4} md={2}>
            <p>NIKLAS KUPPER<br />Guitars/Backing Vocals</p>
          </Col>
          <Col xs={4} sm={4} md={2}>
            <p>TEDDY MÖLLER<br />Guitars/Vocals</p>
          </Col>
          <Col xs={4} sm={4} md={2}>
            <p>PATRIK ORWALD<br />Bass</p>
          </Col>
        </Row>
        <Row id="music-videos">
          <Col xs={12}>
            <iframe className="hero" width="100%" height="582" src="https://www.youtube.com/embed/8h03p__Mb6g" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <h3>Distant Assistance 2024</h3>
          </Col>
          <Col md={6}>
          <iframe className="hero" width="100%" height="315" src="https://www.youtube.com/embed/r5UMHxTT_94" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <h3>Enter the Resistance 2021</h3>
          </Col>
          <Col md={6}>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/szGJBTb3-Hc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <h3>Disillusion 2020</h3>
          </Col>
          <Col md={6}>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/B22znJS16lM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <h3>When the Wolves Have Eaten Everything 2020</h3>
          </Col>
          <Col md={6}>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/0Uorf3HqiBA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <h3>Sacred Structure 2011</h3>
          </Col>
        </Row>
        <Row className="upcoming shows">
          <Col xs={12}>
            <h1>Upcoming Shows</h1>
            {shows.upcoming && shows.upcoming.map((show, index) => (
              <div key={index} className="show">
                <p>
                  <a href="{show.link}" target="_blank"><h3>{show.city}, {show.country} - {show.name}</h3></a>
                </p>
                <p>{show.date}</p>
                <p><i>{show.description}</i></p>
              </div>))}
          </Col>
        </Row>
        <hr />
        <Row className="extended-release">
          <Col sm={12}>
            <h1>Releases</h1>
          </Col>
          <Col md={4} sm={5} xs={12}>
            <img src="img/OpusFeroxII-MarkOfTheBeast_digital_1024x1024.jpg" alt="Opus Ferox II - Mark of the Beast" />
          </Col>
          <Col md={8} sm={7} xs={12} className="album-info">
            <div>
              <h3>Opus Ferox II – Mark of the Beast</h3>
              2024 ViciSolum Productions
            </div>
            <div>
              <b>AVAILABLE ON</b>
              <ul>
                <li>Streaming & download</li>
                <li>CD</li>
                <li>Vinyl - Purple and Marbled Orange Limited Editions</li>
              </ul>
            </div>
            <div className="streaming">
              <a href="https://open.spotify.com/album/6WgvQehin5PWZHg6xS8Cii" target="_blank" rel="noopener noreferrer"><img src="img/music-service_spotify.png" width="100px" height="auto" alt="Spotify" /></a>
              <a href="https://music.apple.com/se/album/opus-ferox-ii-mark-of-the-beast/1724058315" target="_blank" rel="noopener noreferrer"><img src="img/music-service_applemusic_listen.png" alt="Apple Music" /></a>
              <a href="https://lochvostok.bandcamp.com/album/opus-ferox-ii-mark-of-the-beast" target="_blank" rel="noopener noreferrer"><img src="img/BandCamp-Logo.png" alt="Bandcamp" /></a>
            </div>
          </Col>
        </Row>
        <Row className="reviews">
          <Col sm={6} lg={4} className="review">
            <a href="https://metal-heads.de/reviews/loch-vostok-opus-ferox-ii-mark-of-the-beast-review/" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal-heads.de</span> - <span className="score">10/10</span><br />
            <p>“The songs on „Opus Ferox II-Mark Of The Beast“ interweave the various musical threads even more finely, but the band also scales new heights of heaviness and lets it rip. The atmospheric component is even more emphasized, creating a darker world compared to its predecessor”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://metal-temple.com/review/loch-vostok-opus-ferox-ii-mark-of-the-beast/" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal-temple.de</span> - <span className="score">9/10</span><br />
            <p>“The progressive heaviness of the music adds a raw and intense energy. Yet, amidst the heaviness, there is a melodically blissful quality that provides a sense of balance and harmony. It's this balance between technicality, intensity, and beauty that elevates the album to greatness, creating a listening experience that is truly unforgettable.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://metal-digest.com/2024/04/28/loch-vostok-opus-ferox-ii-mark-of-the-beast/" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal-digest.com</span> - <span className="score">9/10</span><br />
            <p>”Opus Ferox II,’ not only has something for everyone, it has everything for everyone. Songs run the gamut between raw yet refined and soaring brilliantly. All instrumentation starts at the next level and then goes even higher, while vocalist Jonas Radehorn’s range is uncanny as he performs with utmost conviction.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://deadrhetoric.com/reviews/loch-vostok-opus-ferox-ii-mark-of-the-beast-vicisolum-productions/" rel="noopener noreferrer" target="_blank"><span className="magazine">Dead Rethoric</span> - <span className="score">9/10</span><br />
            <p>”A sequel should always be able to take the strongest elements of its original presentation to amplify those aspects further. That’s what Loch Vostok sought to achieve – and have – through Opus Ferox II – Mark of the Beast. Where will they go on the finale? Anticipation is high that they’ll go for broke in the details to execute another blockbuster modern progressive metal product.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://metalfactory.ch/music-reviews/aktuell/lp-cd-reviews/review/loch-vostok-opus-ferox-ii-mark-of-the-beast" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal Factory</span> - <span className="score">8.9/10</span><br />
            <p>“For me, "Opus Ferrox II" is much stronger than its predecessor and is therefore a recommendation for fans of prog metal. Listen, enjoy and maybe even headbang.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://actainfernalis.com/2024/04/19/review-2175-loch-vostok-opus-ferox-ii-mark-of-the-beast-english/" rel="noopener noreferrer" target="_blank"><span className="magazine">Acta Infernalis</span> - <span className="score">85/100</span><br />
            <p>“Opus Ferox II – Mark of the Beast isn’t just the second part of a logical sequel, it’s a real outlet for Loch Vostok. More aggressive than its predecessor, the album remains rooted in Progressive Metal, and is a real treat for technicians!”
            </p></a>
          </Col>
          {/* <Col sm={6} lg={4} className="review">
            <a href="https://www.metalinside.de/review/loch-vostok/opus-ferox-ii-mark-of-the-beast" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal Inside</span> - <span className="score"></span><br />
            <p>“LOCH VOSTOK repeatedly remind us of an illegitimate child of their compatriots from EVERGREY and SCAR SYMMETRY with some modern embellishments of the PROTEST THE HERO brand, as the song "Senses" probably best illustrates.”
            </p></a>
            </Col> */}
          <Col sm={6} lg={4} className="review">
            <a href="https://www.ffm-rock.de/reviews-interviews/cd-reviews/61492-loch-vostok-opus-ferox-ii-mark-of-the-beast.html" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal Inside</span> - <span className="score">8.5/10</span><br />
            <p>“The album offers pretty much everything for a traditional prog metal fan and doesn't shy away from modern influences either. Great!”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://theprogressivesubway.com/2024/04/26/review-loch-vostok-opus-ferox-ii-mark-of-the-beast/" rel="noopener noreferrer" target="_blank"><span className="magazine">The Progressive Subway</span> - <span className="score">7/10</span><br />
            <p>“Opus Ferox II manages, somehow, to make a compelling brew out of its several component ingredients. The root beer of its soaring power metal choruses, the Coke of its crunchy, chuggy riffs and snarls, the, uh… Mountain Dew of its ’80s-influenced synths– it all comes together to make something sweet and satisfying”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://www.rockcastlefranken.de/reviews/j-l/loch-vostok-opus-ferox-ii-mark-of-the-beast/" rel="noopener noreferrer" target="_blank"><span className="magazine">Rock Castle Franken</span> - <span className="score">8/10</span><br />
            <p>“Opus Ferox II - Mark Of The Beast is a strong, modern prog metal album with many harder quotes from the areas of thrash and death metal, but without drifting too far into these genres. Much is reminiscent of bands like Evergrey.”
            </p></a>
          </Col>
        </Row>
        <Row className="extended-release">
          <Col md={4} sm={5} xs={12}>
            <img src="img/LV_OpusFerox_Digital-1024x1024.jpeg" alt="Opus Ferox - The Great Escape" />
          </Col>
          <Col md={8} sm={7} xs={12} className="album-info">
            <div>
              <h3>Opus Ferox - The Great Escape</h3>
              2021 ViciSolum Productions
            </div>
            <div>
              <b>AVAILABLE ON</b>
              <ul>
                <li>Streaming & download</li>
                <li>CD with a 16-page booklet and the bonus track “Black Neon Manifesto”</li>
                <li>Vinyl - Limited Edition “Cloudy Silver” & “Black” including a full size 8-page booklet</li>
              </ul>
            </div>
            <div className="streaming">
              <a href="https://open.spotify.com/album/0jDkiIqiXdEv5W0VBAKKGZ" target="_blank" rel="noopener noreferrer"><img src="img/music-service_spotify.png" width="100px" height="auto" alt="Spotify" /></a>
              <a href="https://music.apple.com/se/album/opus-ferox-the-great-escape/1558890657" target="_blank" rel="noopener noreferrer"><img src="img/music-service_applemusic_listen.png" alt="Apple Music" /></a>
              <a href="https://lochvostok.bandcamp.com/album/opus-ferox-the-great-escape" target="_blank" rel="noopener noreferrer"><img src="img/BandCamp-Logo.png" alt="Bandcamp" /></a>
            </div>
          </Col>
        </Row>
        <Row className="reviews">
          <Col sm={6} lg={4} className="review">
            <a href="https://metal-heads.de/reviews/loch-vostok-opus-ferox-the-great-escape-review" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal-heads.de</span> - <span className="score">10/10</span><br />
            <p>“LOCH VOSTOK have really done a great job with the songwriting and woven together a varied, but still homogeneous album. So the listener can expect dreamy melodies next to massive death metal parts and powerful power metal choruses.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="http://www.metal-temple.com/site/catalogues/entry/reviews/cd_3/l_2/loch-vostok-opus.htm" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal-temple.com</span> - <span className="score">10/10</span><br />
            <p>“LOCH VOSTOK have knocked it out of the park with this one. The further you get in this album, the more you love it. I don’t foresee you skipping a single track on this one because it is a heavy, energetic ride from end to end. It’s going to make a perfect addition to any Metalhead’s CD collection. Bravo, LOCH VOSTOK!”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://www.themetalmag.com/loch-vostok-opus-ferox-the-great-escape-lp-cd-25th-june-2021-black-lodge/" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal Mag</span> - <span className="score">100/100</span><br />
            <p>“Eleven songs with the bonus one, an amazing cover artwork to make sure you will get the LP version. Loch Vostok never disappoint, always getting the best to bring the most original tunes. Get onto this journey where music is a special experience that won’t leave you untouched.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://avenoctum.com/2021/06/21/loch-vostok-opus-ferox-the-great-escape-vicisolum/" rel="noopener noreferrer" target="_blank"><span className="magazine">Ave Noctum</span> - <span className="score">9.5/10</span><br />
            <p>“Full of explosive creativity and imagination, “Opus Ferox – The Great Escape” comprises one great song after another. A magnum opus indeed. This is in its musical concept and delivery a stratospherically brilliant album.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://www.greekrebels.gr/loch-vostok-opus-ferox-the-great-escape-vicisolum-productions" rel="noopener noreferrer" target="_blank"><span className="magazine">Greekrebels.gr</span> - <span className="score">9/10</span><br />
            <p>”The bottom line is that "Opus Ferox - The Great Escape" for me personally is already in the top twenty of the year, as this album contains everything to listen to.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://worldofmetalmag.com/album-do-mes-julho-2021/" rel="noopener noreferrer" target="_blank"><span className="magazine">World of Metal</span> - <span className="score">9/10</span><br />
            <p>“From beginning to end, we have fantastic songs where the melodies are combined with the high technical skills of its instrumentalists and where everyone is in tune to make a great album.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <span className="magazine">Sweden Rock Magazine</span> - <span className="score">8/10</span><br />
            <p>“I got a bit sad when Teddy Möller announced his departure from singing in Loch Vostok, but a minute or so into the opener “The Freedom Paradox” my doubts were gone. Jonas Radehorn possesses a wide vocal range that cries of feeling. Perhaps a crossbreed of Messiah Marcolin and Pain of Salvation’s Daniel Gildenlöw.”
            </p>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://www.metalepidemic.com/loch-vostok-opus-ferox-the-great-escape/" rel="noopener noreferrer" target="_blank"><span className="magazine">Metalepidemic.com</span> - <span className="score">4/5</span><br />
            <p>”OPUS FEROX is a beyond solid debut for this new paradigm in Loch Vostok’s long, storied history. Radehorn’s vocal diversity is a solid feather in their cap, and Möeller shows he’s still not short on ideas when it comes to the songwriting. It’s hard to be mad about a singer shake-up like this when the songs speak for themselves.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://time-for-metal.eu/loch-vostok-opus-ferox-the-great-escape" rel="noopener noreferrer" target="_blank"><span className="magazine">Time-for-metal.de</span> - <span className="score">8.8/10</span><br />
            <p>“A top notch progressive album that is on point. I like the variations that come up again and again within the songs and the harmony, which partly results from biting rhythms and melodies.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://mhf-mag.com/reviews/loch-vostok-opus-ferox-the-great-escape" rel="noopener noreferrer" target="_blank"><span className="magazine">Mhf-mag.com</span> - <span className="score">8.3/10</span><br />
            <p>“Opus Ferox – The Great Escape is an extremely successful album. The new man at the microphone has passed his baptism of fire with flying colors.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://stargazed.net/reviews/loch-vostok-opus-ferox-the-great-escape/" rel="noopener noreferrer" target="_blank"><span className="magazine">Stargazed</span> - <span className="score">8/10</span><br />
            <p>“This progressive metal is what the world needs. A saviour of the genre or a proud torch-bearer to the next generation of bands in this genre. A rough diamond waiting to be discovered by those with a weakness for great melodies, fantastic vocals and a more technical touch to the music.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://www.screamblastrepeat.com/loch-vostok-opus-ferox-the-great-escape/" rel="noopener noreferrer" target="_blank"><span className="magazine">Scream Blast Repeat</span> - <span className="score">7/10</span><br />
            <p>“Loch Vostok play by no rules and deserve praise for the way they write with subtle humour and sophistication. It might concern you how much you like this record.”
            </p></a>
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://therapythroughalense.co.uk/new-album-reviews-june" rel="noopener noreferrer" target="_blank"><span className="magazine">Therapy Through a Lense</span></a> - <span className="score">9.7/10</span><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://www.metal-only.de/info-center/reviews/show/134959-loch-vostok-opus-ferox-the-great-escape.html" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal Only</span></a> - <span className="score">9/10</span><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://actainfernalis.com/2021/06/23/review-709-loch-vostok-opus-ferox-the-great-escape-english/" rel="noopener noreferrer" target="_blank"><span className="magazine">Acta Infernalis</span></a> - <span className="score">85/100</span><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <span className="magazine">Legacy Magazine</span> - <span className="score">13/15</span><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <span className="magazine">Scream Magazine Norway</span> - <span className="score">5/6</span><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://metalitalia.com/album/loch-vostok-opus-ferox-the-great-escape/" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal Militia</span></a> - <span className="score">7.5/10</span><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://www.metalfactory.ch/music-reviews/aktuell/lp-cd-reviews/review/loch-vostok-opus-ferox-the-great-escape" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal Factory</span></a> - <span className="score">7.5/10</span><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://metal-digest.com/2021/06/29/loch-vostok-opus-ferox-the-great-escape/" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal Digest</span></a> - <span className="score">70%</span><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://www.metal.de/reviews/loch-vostok-opus-ferox-the-great-escape-420800/" rel="noopener noreferrer" target="_blank"><span className="magazine">Metal.de</span></a> - <span className="score">6/10</span><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://m.thrashocore.com/chroniques/chronique/10175-loch-vostok-opus-ferox-the-great-escape-2021-chronique.html" rel="noopener noreferrer" target="_blank"><span className="magazine">Thrashocore</span></a><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://www.bleeding4metal.de/index.php?show=review_de&id=12545" rel="noopener noreferrer" target="_blank"><span className="magazine">Bleeding4Metal</span></a><br />
          </Col>
          <Col sm={6} lg={4} className="review">
            <a href="https://www.themedianman.com/loch-vostok-opus-ferox/" rel="noopener noreferrer" target="_blank"><span className="magazine">Median Man</span></a><br />
          </Col>
        </Row>
        <Row id="old-releases">
          <Row>
            <Col md={4} sm={5} xs={12}>
              <img src="img/img_0412-1-624x624.jpeg" alt="Strife" />
            </Col>
            <Col md={8} sm={7} xs={12} className="album-info">
              <div>
                <h3>Strife</h3>
                2017 ViciSolum Productions
              </div>
              <div>
                <b>AVAILABLE ON</b>
                <ul>
                  <li>Streaming & download</li>
                  <li>CD</li>
                </ul>
              </div>
              <div className="streaming">
                <a href="https://open.spotify.com/album/0bzY4ouzpHwg3DVBPqLZWQ?si=ez_ekq0GQeqwq49YI03spA" target="_blank" rel="noopener noreferrer"><img src="img/music-service_spotify.png" width="100px" height="auto" alt="Spotify" /></a>
                <a href="https://music.apple.com/se/album/strife/1289934470" target="_blank" rel="noopener noreferrer"><img src="img/music-service_applemusic_listen.png" alt="Apple Music" /></a>
                <a href="https://lochvostok.bandcamp.com/album/strife" target="_blank" rel="noopener noreferrer"><img src="img/BandCamp-Logo.png" alt="BandCamp" /></a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={5} xs={12}>
              <img src="img/fromthesewaters.jpg" alt="From These Waters" />
            </Col>
            <Col md={8} sm={7} xs={12} className="album-info">
              <div>
                <h3>From These Waters</h3>
                2015 ViciSolum Productions
              </div>
              <div>
                <b>AVAILABLE ON</b>
                <ul>
                  <li>Streaming & download</li>
                  <li>CD</li>
                </ul>
              </div>
              <div className="streaming">
                <a href="https://open.spotify.com/album/0EH7S5qY9SvNM6WZJ3wvoP?si=qzfl6g3pT26I9G3xSNdn1A" target="_blank" rel="noopener noreferrer"><img src="img/music-service_spotify.png" width="100px" height="auto" alt="Spotify" /></a>
                <a href="https://music.apple.com/se/album/from-these-waters/949231772" target="_blank" rel="noopener noreferrer"><img src="img/music-service_applemusic_listen.png" alt="Apple Music" /></a>
                <a href="https://lochvostok.bandcamp.com/album/from-these-waters" target="_blank" rel="noopener noreferrer"><img src="img/BandCamp-Logo.png" alt="BandCamp" /></a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={5} xs={12}>
              <img src="img/doctrine.jpeg" alt="V: The Doctrine Decoded" />
            </Col>
            <Col md={8} sm={7} xs={12} className="album-info">
              <div>
                <h3>V: The Doctrine Decoded</h3>
                2012 ViciSolum Productions
              </div>
              <div>
                <b>AVAILABLE ON</b>
                <ul>
                  <li>Streaming & download</li>
                  <li>CD</li>
                </ul>
              </div>
              <div className="streaming">
                <a href="https://open.spotify.com/album/1hDRpkodJPKE6BFW3Swa2Q?si=eJ-P3NCcRrSevoSjMOqLPw" target="_blank" rel="noopener noreferrer"><img src="img/music-service_spotify.png" width="100px" height="auto" alt="Spotify" /></a>
                <a href="https://music.apple.com/se/album/v-the-doctrine-decoded/557810650" target="_blank" rel="noopener noreferrer"><img src="img/music-service_applemusic_listen.png" alt="Apple Music" /></a>
                <a href="https://lochvostok.bandcamp.com/album/v-the-doctrine-decoded" target="_blank" rel="noopener noreferrer"><img src="img/BandCamp-Logo.png" alt="BandCamp" /></a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={5} xs={12}>
              <img src="img/dystopium.jpeg" alt="Dystopium" />
            </Col>
            <Col md={8} sm={7} xs={12} className="album-info">
              <div>
                <h3>Dystopium</h3>
                2011 ViciSolum Productions
              </div>
              <div>
                <b>AVAILABLE ON</b>
                <ul>
                  <li>Streaming & download</li>
                  <li>CD</li>
                </ul>
              </div>
              <div className="streaming">
                <a href="https://open.spotify.com/album/6wrf3qVF5Dvkh3efophUk9?si=PAEYhlOMSMWEsKYrb-rGbg" target="_blank" rel="noopener noreferrer"><img src="img/music-service_spotify.png" width="100px" height="auto" alt="Spotify" /></a>
                <a href="https://music.apple.com/se/album/dystopium/454147809" target="_blank" rel="noopener noreferrer"><img src="img/music-service_applemusic_listen.png" alt="Apple Music" /></a>
                <a href="https://lochvostok.bandcamp.com/album/dystopium" target="_blank" rel="noopener noreferrer"><img src="img/BandCamp-Logo.png" alt="BandCamp" /></a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={5} xs={12}>
              <img src="img/revealnosecrets.jpeg" alt="Reveal No Secrets" />
            </Col>
            <Col md={8} sm={7} xs={12} className="album-info">
              <div>
                <h3>Reveal No Secrets</h3>
                2009  Nightmare Records
              </div>
              <div>
                <b>AVAILABLE ON</b>
                <ul>
                  <li>Streaming & download</li>
                  <li>CD</li>
                </ul>
              </div>
              <div className="streaming">
                <a href="https://open.spotify.com/album/6UPXbX3tbWeseo6Mz64zVg?si=iovaAuNLTZ-m-8sMaJb8XA" target="_blank" rel="noopener noreferrer"><img src="img/music-service_spotify.png" width="100px" height="auto" alt="Spotify" /></a>
                <a href="https://music.apple.com/se/album/reveal-no-secrets/316963379" target="_blank" rel="noopener noreferrer"><img src="img/music-service_applemusic_listen.png" alt="Apple Music" /></a>
                <a href="https://lochvostok.bandcamp.com/album/reveal-no-secrets" target="_blank" rel="noopener noreferrer"><img src="img/BandCamp-Logo.png" alt="BandCamp" /></a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={5} xs={12}>
              <img src="img/Destruction-Time-Again.jpg" alt="Destruction Time Again" />
            </Col>
            <Col md={8} sm={7} xs={12} className="album-info">
              <div>
                <h3>Destruction Time Again</h3>
                2006 Escapi Music
              </div>
              <div>
                <b>AVAILABLE ON</b>
                <ul>
                  <li>Streaming & download</li>
                  <li>CD</li>
                </ul>
              </div>
              <div className="streaming">
                <a href="https://open.spotify.com/album/7btSnQBOoyxXgp2bHsG6Nt?si=DYBqUbcdRh2KloX99fdBtw" target="_blank" rel="noopener noreferrer"><img src="img/music-service_spotify.png" width="100px" height="auto" alt="Spotify" /></a>
                <a href="https://music.apple.com/se/album/destruction-time-again/160467047" target="_blank" rel="noopener noreferrer"><img src="img/music-service_applemusic_listen.png" alt="Apple Music" /></a>
                <a href="https://lochvostok.bandcamp.com/album/destruction-time-again" target="_blank" rel="noopener noreferrer"><img src="img/BandCamp-Logo.png" alt="BandCamp" /></a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={5} xs={12}>
              <img src="img/dark_logic.jpg" alt="Dark Logic" />
            </Col>
            <Col md={8} sm={7} xs={12} className="album-info">
              <div>
                <h3>Dark Logic</h3>
                2004 Magnetism Records<br />2003 CD-Maximum
              </div>
              <div>
                <b>AVAILABLE ON</b>
                <ul>
                  <li>Streaming & download</li>
                  <li>CD</li>
                </ul>
              </div>
              <div className="streaming">
                <a href="https://open.spotify.com/album/0duJDFLPQlxD0pweL9raew?si=20JU4ZP9QeKx7j2tuIiMvA" target="_blank" rel="noopener noreferrer"><img src="img/music-service_spotify.png" width="100px" height="auto" alt="Spotify" /></a>
                <a href="https://music.apple.com/se/album/dark-logic/1149923710" target="_blank" rel="noopener noreferrer"><img src="img/music-service_applemusic_listen.png" alt="Apple Music" /></a>
                <a href="https://lochvostok.bandcamp.com/album/dark-logic" target="_blank" rel="noopener noreferrer"><img src="img/BandCamp-Logo.png" alt="BandCamp" /></a>
              </div>
            </Col>
          </Row>
          <Row id="distribution">
            <Col xs={12}>
              <h1>Distribution</h1>
            </Col>
            <Col md={6}><span className="dist">ALTAFONE</span> - Spain, Portugal</Col>
            <Col md={6}><span className="dist">AUDIOGLOBE SRL</span> - Italy</Col>
            <Col md={6}><span className="dist">BICKEE MUSIC</span> - Japan</Col>
            <Col md={6}><span className="dist">DOPEMUSIC</span> - South Korea</Col>
            <Col md={6}><span className="dist">INFINITY ENTERTAINMENT</span> - Greece</Col>
            <Col md={6}><span className="dist">NON STOP MUSIC RECORDS</span> - Switzerland</Col>
            <Col md={6}><span className="dist">PLASTIC HEAD MUSIC DISTRIBUTION</span> - England</Col>
            <Col md={6}><span className="dist">ROUGH TRADE DISTRIBUTION GMBH</span> - Germany</Col>
            <Col md={6}><span className="dist">SONY MUSIC</span> - Japan</Col>
            <Col md={6}><span className="dist">SOUND POLLUTION</span> - Scandinavia</Col>
            <Col md={6}><span className="dist">SOUNDWORKS/SEASON OF MIST</span> - France</Col>
            <Col md={6}><span className="dist">SUBURBAN B.V.</span> - Holland</Col>
            <Col md={6}><span className="dist">SUPER D / CD LISTENING BAR, INC</span> - USA</Col>
            <Col md={6}><span className="dist">SUPERSOUNDS</span> - Finland</Col>
            <Col md={6}><span className="dist">MATRIX MUSIC</span> - Croatia, Serbia, Bosnia-Herzegovina, Macedonia, Montenegro, Kosovo</Col>
            <Col md={6}><span className="dist">MYSTIC PRODUCTION</span> - Poland, Check Republic, Slovakia, Croatia, Romania, Hungary</Col>
          </Row>
        </Row>
        <Row id="live">
          <Col xs={12}>
            <h1>Live</h1>
          </Col>
          <Images />
        </Row>
        <Row className="past shows">
          <Col xs={12}>
            <h1>Past Shows</h1>
            {shows.past && shows.past.map((show, index) => (
              show.tour ? (
                <React.Fragment key={index}>
                  <h4>{show.tour}</h4>
                  {show.shows.map((subShow, subIndex) => (
                    <span key={subIndex}>
                      {subShow.date} {subShow.city}, {subShow.country} - {subShow.name}<br />
                      <i>{subShow.description}</i>
                    </span>
                  ))}
                </React.Fragment>
              ) : (
                <p key={index}>
                  {show.date} {show.city}, {show.country} - {show.name}<br />
                  <i>{show.description}</i>
                </p>
              )
            ))}
          </Col>
        </Row>
        <Row id="footer">
          <Col xs={12}>
            <hr />
            Contact: <a href="mailto:info@lochvostok.com">info@lochvostok.com</a>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default App;
